import { useCallback, useEffect, useRef, useState } from "react";
import styles from "./style.module.scss";
import Particles from "react-particles";
import { loadSlim } from "tsparticles-slim";
import { Engine } from "tsparticles-engine";
import { PUBLIC_SVG_PATH } from "../../constants/paths";
import { getBreakpoints } from "../../utils/breakpointsUitls";

type Props = {
  goNextPageIndex: (index: number) => void;
  currentPageIndex: number;
  titles: { text: string; subtext: string; href: string; source: { movie: string, img: string } }[];
  sectionTitle: string;
  downloads: { text: string; href: string; icon: string }[];
};

const DownloadSection: React.FC<Props> = ({
  goNextPageIndex,
  currentPageIndex,
  titles,
  sectionTitle,
  downloads,
}) => {
  const cancriRef = useRef<HTMLVideoElement>(null);
  const plontRef = useRef<HTMLVideoElement>(null);
  const [lastScrollTop, setLastScrollTop] = useState(0); // 最後のスクロール位置を保持
  // const [isFirstReload, setIsFirstReload] = useState(true); // 最後のスクロール位置を保持
  const [isScrollDown, setIsScrollDown] = useState(false);


  //初回起動時に開始ポジションまで動画を移動
  useEffect(() => {
    if (getBreakpoints() == "sp") return;


    document.body.style.overflow = "";
    setIsScrollDown(false);
  }, []);

  //下にスクロールして、次のページに遷移
  useEffect(() => {
    if (getBreakpoints() == "sp") return;

    // スクロールイベントハンドラ
    const handleScroll = () => {
      const currentScrollTop = window.scrollY;

      console.log("currentScrollTop", currentScrollTop);
      console.log("lastScrollTop", lastScrollTop);

      //前ののページに遷移
      const scrollUp = currentScrollTop < lastScrollTop;
      if (scrollUp) {
        goNextPageIndex(currentPageIndex - 1);
        setIsScrollDown(false);
      }

      setLastScrollTop(currentScrollTop);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollTop]);

  const particlesInit = useCallback(async (engine: Engine) => {
    await loadSlim(engine);
  }, []);

  const particlesLoaded = useCallback(async (container: any) => {
    await console.log(container);
  }, []);

  return (
    <div className={styles.container}>
      <div
        className={`${styles.titles} ${styles.bounceInRight} ${isScrollDown ? styles.slideLeftFadeOut : ""
          }`}
      >
        <h2>{sectionTitle}</h2>

        <div className={styles.buttons}>
          {downloads.map((item, index) => {
            return (
              <a
                key={index}
                className={styles.buttons__button}
                href={item.href}
                target="_blank"
                rel="noreferrer"
              >
                <img src={item.icon} alt="Apple Store Icon" />
                <p>{item.text}</p>
              </a>
            );
          })}
        </div>

        <div className={styles.pagesSegues}>
          {titles.map((item, index) => {
            return (
              <a href={item.href} target="_blank" rel="noreferrer" key={index}>
                <div className={styles.pagesSegues__button}>
                  <h2>{item.text}</h2>
                  <p>{item.subtext}</p>
                  <img src={item.source.img} alt="" />
                  <video
                    ref={cancriRef}
                    src={item.source.movie}
                    muted
                    autoPlay
                    loop
                  ></video>
                </div>
              </a>
            );
          })}
        </div>
      </div>

      <div className={styles.header__bg}>
        <Particles
          id="tsparticles"
          init={particlesInit}
          loaded={particlesLoaded}
          options={{
            fpsLimit: 120,
            interactivity: {
              events: {
                onClick: {
                  enable: true,
                  mode: "push",
                },
                onHover: {
                  enable: true,
                  mode: "repulse",
                },
                resize: true,
              },
              modes: {
                push: {
                  quantity: 4,
                },
                repulse: {
                  distance: 200,
                  duration: 100,
                },
              },
            },
            particles: {
              color: {
                value: ["#ffffff", "#00B0B2", "#F8D700", "#F08300", "#EA544F"],
              },
              links: {
                color: "#ffffff",
                distance: 0,
                enable: true,
                opacity: 0.5,
                width: 1,
              },
              move: {
                direction: "none",
                enable: true,
                outModes: {
                  default: "bounce",
                },
                random: false,
                speed: 0.3,
                straight: false,
              },
              number: {
                density: {
                  enable: true,
                  area: 800,
                },
                value: 20,
              },
              opacity: {
                value: 0.5,
              },
              shape: {
                type: "circle",
              },
              size: {
                value: { min: 1, max: 3 },
              },
            },
            detectRetina: true,
          }}
        />
      </div>
    </div>
  );
};

export default DownloadSection;
