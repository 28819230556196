import React from "react";
import styles from "./Loader.module.scss";
import { PUBLIC_IMAGE_PATH } from "../../constants/paths";

const Loader = () => {
  const bars = new Array(25).fill(null);
  const colors = ["#000"];

  const getRandomDelay = (maxDelay: number) => {
    return Math.random() * maxDelay;
  };

  const getRandomColor = () => {
    return colors[Math.floor(Math.random() * colors.length)];
  };

  return (
    <div className={styles.container}>
      {bars.map((_, index) => (
        <img
          key={index}
          className={styles.bar1}
          src={`${PUBLIC_IMAGE_PATH}/lp/hologram.png`}
          alt=""
          style={{
            animationDelay: `${getRandomDelay(0.1)}s`,
            backgroundColor: getRandomColor(), // Random color
          }}
        />
      ))}
    </div>
  );
};

export default Loader;
