import {
  PUBLIC_IMAGE_PATH,
  PUBLIC_MOVIE_PATH,
  PUBLIC_SVG_PATH,
} from "../../constants/paths";
import { useEffect, useState } from "react";
import Loader from "../../component/Loader";
import LpSection from "../../component/LpSection";
import TopSection from "../../component/TopSection";
import DownloadSection from "../../component/DownloadSection";
import styles from "./style.module.scss";
import Header from "../../component/Header";
import HambergerMenu from "../../component/HambergerMenu";
import MetaDescription from "../../component/MetaDescription";

const Home: React.FC = () => {
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState<boolean | null>(null);
  const SCREEN_WIDTH = window.innerWidth;

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  }, [currentPageIndex]);

  const headerTitles = [
    {
      title: "home",
      source: `${PUBLIC_IMAGE_PATH}/bg/pc/top.png`,
      stopTime: 800,
      onClick: () => {
        setCurrentPageIndex(0);
      },
    },
    {
      title: "snsMap",
      stopTime: 800,
      source: `${PUBLIC_IMAGE_PATH}/bg/pc/sns.png`,
      onClick: () => {
        setCurrentPageIndex(1);
        setIsLoading(true);
      },
    },
    {
      title: "buzzLevel",
      stopTime: 800,
      source: `${PUBLIC_IMAGE_PATH}/bg/pc/fire.png`,
      onClick: () => {
        setCurrentPageIndex(2);
        setIsLoading(true);
      },
    },
    {
      title: "money",
      stopTime: 800,
      source: `${PUBLIC_IMAGE_PATH}/bg/pc/jewelry.png`,
      onClick: () => {
        setCurrentPageIndex(3);
        setIsLoading(true);
      },
    },
    {
      title: "autoPosts",
      stopTime: 800,
      source: `${PUBLIC_IMAGE_PATH}/bg/pc/map.png`,
      onClick: () => {
        setCurrentPageIndex(4);
        setIsLoading(true);
      },
    },
    {
      title: "other",
      stopTime: 800,
      source: `${PUBLIC_IMAGE_PATH}/bg/pc/plan.png`,
      onClick: () => {
        setCurrentPageIndex(5);
        setIsLoading(true);
      },
    },
  ];

  const downloads = [
    {
      text: "app store",
      href: "https://apps.apple.com/jp/app/hamoni-%E3%83%8F%E3%83%A2%E3%83%8B/id1522639045",
      icon: `${PUBLIC_SVG_PATH}/apple.svg`,
    },
    {
      text: "play store",
      href: "https://play.google.com/store/apps/details?id=jp.hamoni.app",
      icon: `${PUBLIC_SVG_PATH}/google.svg`,
    },
  ];

  return (
    <>
      <MetaDescription title="HAMONI" />
      <Header titles={headerTitles} downloads={downloads} />

      <div
        className={styles.container}
        style={isOpen === true ? { display: "none" } : undefined}
      >
        {(SCREEN_WIDTH < 480 || currentPageIndex === 0) && (
          <TopSection
            subtitle={
              "HAMONIはSNSで「今」バズっているお店だけを集めて、あなたの近い順から投稿を表示してくれる新しいグルメアプリ。SNSでみた、あの投稿が近い順に表示されるよ"
            }
            movie={`${PUBLIC_MOVIE_PATH}/earth.mp4`}
            currentPageIndex={currentPageIndex}
            goNextPageIndex={(index) => {
              setIsLoading(true);
              setTimeout(() => {
                setCurrentPageIndex(index);
              }, 800);
            }}
          />
        )}

        {(SCREEN_WIDTH < 480 || currentPageIndex === 1) && (
          <LpSection
            stopTime={[800]}
            image={[
              {
                source: `${PUBLIC_IMAGE_PATH}/phone/firstPhone.png`,
                side: "right",
              },
            ]}
            texts={[
              {
                title: "SNS MAPPING",
                subtile:
                  "SNS上で見かけたお店の人気度や話題の動画を近い順からマップ表示できるよ。SNS上で流行っているお店や、注目されているスポットをリアルタイムで把握し、新しいお店を発見できるよ。",
                side: "leftTop",
              },
            ]}
            background={{
              movie: `${PUBLIC_MOVIE_PATH}/bg/top.mp4`,
              img: `${PUBLIC_IMAGE_PATH}/bg/sp/top.png`,
            }}
            currentPageIndex={currentPageIndex}
            goNextPageIndex={(index) => {
              setIsLoading(true);
              setTimeout(() => {
                setCurrentPageIndex(index);
              }, 800);
            }}
          />
        )}

        {(SCREEN_WIDTH < 480 || currentPageIndex === 2) && (
          <LpSection
            stopTime={[800]}
            image={[
              {
                source: `${PUBLIC_IMAGE_PATH}/phone/secondPhone.png`,
                side: "left",
              },
            ]}
            texts={[
              {
                title: "BUZZ ONLY SHOPS",
                subtile:
                  "SNS上では、お店がどれほど人気があるかを判断するのは難しいよね。HAMONIは、一日の間にSNS上で行われたすべての投稿を集めて、「バズり度」を出すことで、どのお店が最もバズっているかを明確にするよ。",
                side: "rightTop",
              },
            ]}
            background={{
              movie: `${PUBLIC_MOVIE_PATH}/bg/fire.mp4`,
              img: `${PUBLIC_IMAGE_PATH}/bg/sp/fire.png`,
            }}
            currentPageIndex={currentPageIndex}
            goNextPageIndex={(index) => {
              setIsLoading(true);
              setTimeout(() => {
                setCurrentPageIndex(index);
              }, 800);
            }}
          />
        )}

        {(SCREEN_WIDTH < 480 || currentPageIndex === 3) && (
          <LpSection
            stopTime={[800]}
            image={[
              {
                source: `${PUBLIC_IMAGE_PATH}/phone/thirdPhone.png`,
                side: "right",
              },
            ]}
            texts={[
              {
                title: "SAVE MONEY",
                subtile:
                  "HAMONIの投稿を外部サイトにシェアして見られたり、あなたのファンが投稿から予約がされるとお金が入るしくみだよ。",
                side: "leftTop",
              },
            ]}
            background={{
              movie: `${PUBLIC_MOVIE_PATH}/bg/jewelry.mp4`,
              img: `${PUBLIC_IMAGE_PATH}/bg/sp/jewelry.png`,
            }}
            currentPageIndex={currentPageIndex}
            goNextPageIndex={(index) => {
              setIsLoading(true);
              setTimeout(() => {
                setCurrentPageIndex(index);
              }, 800);
            }}
          />
        )}

        {(SCREEN_WIDTH < 480 || currentPageIndex === 4) && (
          <LpSection
            stopTime={[800]}
            image={[
              {
                source: `${PUBLIC_IMAGE_PATH}/phone/fourthPhone.png`,
                side: "left",
              },
            ]}
            texts={[
              {
                title: "AUTO MAPPING",
                subtile:
                  "ユーザーがインスタグラムアカウントをこのシステムに連携するだけで、自動的に地図上に表示するよ。特別な操作をする必要はないよ。",
                side: "rightTop",
              },
            ]}
            background={{
              movie: `${PUBLIC_MOVIE_PATH}/bg/map.mp4`,
              img: `${PUBLIC_IMAGE_PATH}/bg/sp/map.png`,
            }}
            currentPageIndex={currentPageIndex}
            goNextPageIndex={(index) => {
              setIsLoading(true);
              setTimeout(() => {
                setCurrentPageIndex(index);
              }, 800);
            }}
          />
        )}

        {(SCREEN_WIDTH < 480 || currentPageIndex === 5) && (
          <DownloadSection
            currentPageIndex={currentPageIndex}
            sectionTitle={"OTHRE HAMONI"}
            goNextPageIndex={(index) => {
              setIsLoading(true);
              setTimeout(() => {
                setCurrentPageIndex(index);
              }, 800);
            }}
            titles={[
              {
                text: "ABOUT US",
                subtext: "",
                source: {
                  movie: `${PUBLIC_MOVIE_PATH}/cancri.mp4`,
                  img: `${PUBLIC_IMAGE_PATH}/bg/sp/cancri.png`,
                },
                href: "https://design.hamoni.jp/",
              },
              {
                text: "WEB VERSION",
                subtext: "",
                source: {
                  movie: `${PUBLIC_MOVIE_PATH}/ploto.mp4`,
                  img: `${PUBLIC_IMAGE_PATH}/bg/sp/ploto.png`,
                },
                href: "https://app.hamoni.jp/",
              },
            ]}
            downloads={downloads}
          />
        )}

        {isLoading && <Loader />}
      </div>

      <HambergerMenu
        titles={headerTitles}
        sectionTitle={"Menu"}
        onPress={(isOpen) =>
          setTimeout(() => {
            setIsOpen(isOpen);
          }, 100)
        }
        downloads={[
          {
            text: "app store",
            href: "https://apps.apple.com/jp/app/hamoni-%E3%83%8F%E3%83%A2%E3%83%8B/id1522639045",
            icon: `${PUBLIC_SVG_PATH}/apple.svg`,
          },
          {
            text: "play store",
            href: "https://play.google.com/store/apps/details?id=jp.hamoni.app",
            icon: `${PUBLIC_SVG_PATH}/google.svg`,
          },
        ]}
      />
    </>
  );
};

export default Home;
