import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useLottie, LottieOptions } from "lottie-react";

interface LottieAnimationProps {
  ref: React.Ref<LottieAnimationRef>;
  src: unknown;
  size: number;
  onClick?: () => void;
  isPlaying?: boolean;
  autoPlay?: boolean;
}

export interface LottieAnimationRef {
  playAnimation: () => void;
  playSegments: (start: number, end: number) => void;
  stopAnimationAt: (frame: number) => void;
  reverse: (start: number, end: number) => void;
}

const LottieIcon = forwardRef<LottieAnimationRef, LottieAnimationProps>(
  (props, ref) => {
    const { src, size, autoPlay } = props;

    const options: LottieOptions<"svg"> = {
      animationData: src,
      autoplay: true,
      loop: autoPlay,
    };

    const style = {
      height: size,
      width: size,
    };

    const { View, goToAndPlay, goToAndStop, stop, playSegments, setDirection } =
      useLottie(options, style);

    useImperativeHandle(ref, () => ({
      playAnimation: () => {
        goToAndPlay(0);
      },
      stopAnimationAt: (frame: number) => {
        goToAndStop(frame, true);
      },
      playSegments: (start: number, stop: number) => {
        playSegments([start, stop], true);
      },
      reverse: (start: number, stop: number) => {
        setDirection(-1);
        playSegments([start, stop], true);
      },
    }));

    return View;
  }
);

LottieIcon.displayName = "LottieIcon";

export default LottieIcon;
