import Particles from "react-particles";
import { PUBLIC_LOTTIE_PATH, PUBLIC_SVG_PATH } from "../../constants/paths";
import styles from "./HambergerMenu.module.scss";
import { useCallback, useEffect, useRef, useState } from "react";
import { loadSlim } from "tsparticles-slim";
import { Engine } from "tsparticles-engine";
import LottieIcon, { LottieAnimationRef } from "../LottieIcon/LottieIcon";
import hamburgermenu from "../../lottie/hamburgermenu.json";

type Props = {
  titles: {
    title: string;
    onClick: () => void;
    source: string;
    stopTime?: number;
  }[];
  sectionTitle: string;
  onPress: (isOpen: boolean | null) => void;
  downloads: { text: string; href: string; icon: string }[];
};
const HambergerMenu: React.FC<Props> = ({
  titles,
  sectionTitle,
  onPress,
  downloads,
}) => {
  const particlesInit = useCallback(async (engine: Engine) => {
    await loadSlim(engine);
  }, []);

  const particlesLoaded = useCallback(async (container: any) => {
    await console.log(container);
  }, []);

  const [isOpen, setIsOpen] = useState<boolean | null>(null);
  const ref = useRef<LottieAnimationRef>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    console.log("isOpen", isOpen);
    onPress(isOpen);
    if (isOpen == null) {
      ref.current?.playSegments(0, 0); // 適切なセグメント値に設定
    } else if (isOpen === true) {
      // isOpen が true の場合、アニメーションを正方向で再生
      ref.current?.playSegments(0, 30); // 適切なセグメント値に設定
    } else if (isOpen === false) {
      // isOpen が false の場合、アニメーションを逆方向で再生
      ref.current?.reverse(30, 0); // 適切なセグメント値に設定
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen !== null && containerRef.current) {
      console.log("isOpen", isOpen);
      // 既存のタイマーがあればクリア
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      // アニメーションクラスを削除
      containerRef.current.classList.remove(
        styles.openHambergerMenu,
        styles.closeHambergerMenu
      );

      // 適切なアニメーションクラスを再追加
      timeoutRef.current = setTimeout(() => {
        containerRef.current?.classList.add(
          isOpen ? styles.openHambergerMenu : styles.closeHambergerMenu
        );
      }, 10);
    }

    // useEffect のクリーンアップ関数
    return () => {
      // コンポーネントがアンマウントされる際にタイマーをクリア
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [isOpen]);

  const videoRefs = useRef<(HTMLVideoElement | null)[]>([]);

  useEffect(() => {
    videoRefs.current = videoRefs.current.slice(0, titles.length);
  }, [titles]);

  const handleVideoClick = (index: number) => {
    const video = videoRefs.current[index];
    const stopTime = titles[index].stopTime;

    if (video && typeof stopTime === "number") {
      video.currentTime = stopTime;
      video.pause();
    }
  };

  useEffect(() => {
    titles.map((_, index) => {
      handleVideoClick(index);
    });
  }, []);

  return (
    <>
      <div
        className={styles.menu}
        onClick={() => {
          if (isOpen == null || isOpen === false) {
            setIsOpen(true);
          } else {
            setIsOpen(false);
          }
        }}
      >
        <LottieIcon ref={ref} src={hamburgermenu} size={32} autoPlay={false} />
      </div>

      <div
        ref={containerRef}
        className={`${styles.container} ${isOpen === true && styles.openHambergerMenu
          } ${isOpen === false && styles.closeHambergerMenu}`}
      >
        <div className={`${styles.titles} ${styles.bounceInRight}`}>
          <h2>{sectionTitle}</h2>

          <div className={styles.buttons}>
            {downloads.map((item, index) => {
              return (
                <a
                  key={index}
                  className={styles.buttons__button}
                  href={item.href}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={item.icon} alt="Apple Store Icon" />
                  <p>{item.text}</p>
                </a>
              );
            })}
          </div>

          <div className={styles.pagesSegues}>
            {titles.map((item, index) => {
              return (
                <div
                  key={index}
                  onClick={() => {
                    item.onClick();
                    setIsOpen(false);
                  }}
                >
                  <div className={styles.pagesSegues__button}>
                    <h2>{item.title}</h2>
                    <img src={item.source} alt="" />
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        {/* <div className={styles.header__bg}>
          <Particles
            id="tsparticles"
            init={particlesInit}
            loaded={particlesLoaded}
            options={{
              fpsLimit: 120,
              interactivity: {
                events: {
                  onClick: {
                    enable: true,
                    mode: "push",
                  },
                  onHover: {
                    enable: true,
                    mode: "repulse",
                  },
                  resize: true,
                },
                modes: {
                  push: {
                    quantity: 4,
                  },
                  repulse: {
                    distance: 200,
                    duration: 100,
                  },
                },
              },
              particles: {
                color: {
                  value: [
                    "#ffffff",
                    "#00B0B2",
                    "#F8D700",
                    "#F08300",
                    "#EA544F",
                  ],
                },
                links: {
                  color: "#ffffff",
                  distance: 0,
                  enable: true,
                  opacity: 0.5,
                  width: 1,
                },
                move: {
                  direction: "none",
                  enable: true,
                  outModes: {
                    default: "bounce",
                  },
                  random: false,
                  speed: 0.3,
                  straight: false,
                },
                number: {
                  density: {
                    enable: true,
                    area: 800,
                  },
                  value: 20,
                },
                opacity: {
                  value: 0.5,
                },
                shape: {
                  type: "circle",
                },
                size: {
                  value: { min: 1, max: 3 },
                },
              },
              detectRetina: true,
            }}
          />
        </div> */}
      </div>
    </>
  );
};

export default HambergerMenu;
