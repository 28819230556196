import { useCallback, useEffect, useRef, useState } from "react";
import styles from "./style.module.scss";
import Particles from "react-particles";
import { loadSlim } from "tsparticles-slim";
import { Engine } from "tsparticles-engine";
import { PUBLIC_IMAGE_PATH, PUBLIC_SVG_PATH } from "../../constants/paths";
import { getBreakpoints } from "../../utils/breakpointsUitls";

type Props = {
  movie: string;
  subtitle: string;
  goNextPageIndex: (index: number) => void;
  currentPageIndex: number;
};

const TopSection: React.FC<Props> = ({
  movie,
  subtitle,
  goNextPageIndex,
  currentPageIndex,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [lastScrollTop, setLastScrollTop] = useState(0); // 最後のスクロール位置を保持
  const [isFirstReload, setIsFirstReload] = useState(true); // 最後のスクロール位置を保持
  const [isScrollDown, setIsScrollDown] = useState(false);
  const SCREEN_WIDTH = window.innerWidth;

  //初回起動時に開始ポジションまで動画を移動
  useEffect(() => {
    if (getBreakpoints() == "sp") return;
    document.body.style.overflow = "";
    setIsFirstReload(false);
  }, []);

  //下にスクロールして、次のページに遷移
  useEffect(() => {

    //スマホのサイズ
    if (getBreakpoints() == "sp") return;
    const video = videoRef.current;
    if (!video) return;

    // スクロールイベントハンドラ
    const handleScroll = () => {
      const currentScrollTop = window.scrollY;

      const scrollDown = currentScrollTop > lastScrollTop;

      if (scrollDown && !isFirstReload) {
        // 下にスクロールされたら動画を再生
        console.log("下にスクロール");
        setIsScrollDown(true);
        goNextPageIndex(currentPageIndex + 1);
        document.body.style.overflow = "hidden";

        if (video.paused) {
          video.play();
        }
      }

      setLastScrollTop(currentScrollTop);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollTop]);

  const particlesInit = useCallback(async (engine: Engine) => {
    await loadSlim(engine);
  }, []);

  const particlesLoaded = useCallback(async (container: any) => {
    await console.log(container);
  }, []);

  return (
    <div className={styles.container}>
      <video
        ref={videoRef}
        className={styles.firstVideo}
        src={movie}
        muted
        autoPlay
        loop
      ></video>

      <div
        className={`${styles.titles} ${styles.bounceInRight} ${isScrollDown ? styles.slideLeftFadeOut : ""
          }`}
      >
        <h2>
          SHOPS BUZZING <br /> RIGHT HERE
        </h2>

        <div className={styles.buttons}>
          <a
            className={styles.buttons__button}
            href="https://apps.apple.com/jp/app/hamoni-%E3%83%8F%E3%83%A2%E3%83%8B/id1522639045"
            target="_blank"
            rel="noreferrer"
          >
            <img src={`${PUBLIC_SVG_PATH}/apple.svg`} alt="Apple Store Icon" />
            <p>Apple Store</p>
          </a>

          <a
            className={styles.buttons__button}
            href="https://play.google.com/store/apps/details?id=jp.hamoni.app"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={`${PUBLIC_SVG_PATH}/google.svg`}
              alt="google Store Icon"
            />
            <p>Google Play</p>
          </a>
        </div>

        <p className={`${styles.subtitle} ${styles.slideInFromRight}`}>
          {subtitle}
        </p>

        <div
          className={styles.nextPageButton}
          onClick={() => {
            if (getBreakpoints() == "sp") {
              window.location.href = "https://app.hamoni.jp/";
            } else {
              goNextPageIndex(currentPageIndex + 1)
            }
          }}
        >
          <img src={`${PUBLIC_SVG_PATH}/downArrow.svg`} alt="" />
          <p className={styles.linearWipe}>{getBreakpoints() == "sp" ? "go web page" : "go next page"}</p>
        </div>
      </div>

      {/* {side === "second" ? (
        <img
          className={`${styles.firstPhone} ${styles.secondPhone} ${
            isScrollDown ? styles.rotateAndBounceOut : ""
          }`}
          src={`${PUBLIC_IMAGE_PATH}/lp/secondPhone.png`}
          alt=""
        />
      ) : (
        <img
          className={`${styles.firstPhone} ${
            isScrollDown ? styles.rotateAndBounceOut : ""
          }`}
          src={`${PUBLIC_IMAGE_PATH}/lp/firstPhone.png`}
          alt=""
        />
      )} */}

      <div className={styles.header__bg}>
        <Particles
          id="tsparticles"
          init={particlesInit}
          loaded={particlesLoaded}
          options={{
            fpsLimit: 120,
            interactivity: {
              events: {
                onClick: {
                  enable: true,
                  mode: "push",
                },
                onHover: {
                  enable: true,
                  mode: "repulse",
                },
                resize: true,
              },
              modes: {
                push: {
                  quantity: 4,
                },
                repulse: {
                  distance: 200,
                  duration: 100,
                },
              },
            },
            particles: {
              color: {
                value: ["#ffffff", "#00B0B2", "#F8D700", "#F08300", "#EA544F"],
              },
              links: {
                color: "#ffffff",
                distance: 0,
                enable: true,
                opacity: 0.5,
                width: 1,
              },
              move: {
                direction: "none",
                enable: true,
                outModes: {
                  default: "bounce",
                },
                random: false,
                speed: 0.3,
                straight: false,
              },
              number: {
                density: {
                  enable: true,
                  area: 800,
                },
                value: 20,
              },
              opacity: {
                value: 0.5,
              },
              shape: {
                type: "circle",
              },
              size: {
                value: { min: 1, max: 3 },
              },
            },
            detectRetina: true,
          }}
        />
      </div>
    </div>
  );
};

export default TopSection;
