import {
  PUBLIC_IMAGE_PATH,
  PUBLIC_MOVIE_PATH,
  PUBLIC_SVG_PATH,
} from "../../constants/paths";
import { useEffect, useState } from "react";
import Loader from "../../component/Loader";
import LpSection from "../../component/LpSection";
import TopSection from "../../component/TopSection";
import styles from "./style.module.scss";
import Header from "../../component/Header";
import DownloadSection from "../../component/DownloadSection";
import HambergerMenu from "../../component/HambergerMenu";

const Wallet: React.FC = () => {
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState<boolean | null>(null);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  }, [currentPageIndex]);

  const headerTitles = [
    {
      title: "notification",
      source: `${PUBLIC_IMAGE_PATH}/bg/pc/store.png`,
      onClick: () => {
        setCurrentPageIndex(0);
      },
    },
    {
      title: "offline",
      source: `${PUBLIC_IMAGE_PATH}/bg/pc/izakaya.png`,
      onClick: () => {
        setCurrentPageIndex(1);
        setIsLoading(true);
      },
    },
    {
      title: "install",
      source: `${PUBLIC_IMAGE_PATH}/bg/pc/install.png`,
      onClick: () => {
        setCurrentPageIndex(2);
        setIsLoading(true);
      },
    },
    {
      title: "plan",
      source: `${PUBLIC_IMAGE_PATH}/bg/pc/plan.png`,
      onClick: () => {
        setCurrentPageIndex(3);
        setIsLoading(true);
      },
    },
  ];

  const downloads = [
    {
      text: "app store",
      href: "https://app.hamoni.jp/app_api/priv/event_tickets/ios/sample?username=HamoniMembers",
      icon: `${PUBLIC_SVG_PATH}/apple.svg`,
    },
    {
      text: "play store",
      href: "https://app.hamoni.jp/app_api/priv/event_tickets/android/sample?username=HamoniMembers",
      icon: `${PUBLIC_SVG_PATH}/google.svg`,
    },
  ];

  return (
    <div className={styles.container}>
      <HambergerMenu
        titles={headerTitles}
        sectionTitle={"Menu"}
        onPress={(isOpen) => setIsOpen(isOpen)}
        downloads={downloads}
      />
      <Header titles={headerTitles} downloads={downloads} />

      {currentPageIndex === 0 && (
        <LpSection
          stopTime={[1700, 1000]}
          image={[
            { source: undefined, side: "right" },
            {
              source: `${PUBLIC_IMAGE_PATH}/wallet/AreaSensorPhone.png`,
              side: "right",
            },
          ]}
          texts={[
            {
              title: "DIGITAL WALLET",
              subtile: "",
              side: "leftBottom",
            },
            {
              title: "エリアセンサー通知",
              subtile:
                "ウォレットを入れているお客様がお店の近くに立ち寄ると、エリアセンサーが反応し、通知を送ることができます。ふと近くに通りすがったお客様に対して、効果的にアプローチすることができます。",
              side: "leftTop",
            },
          ]}
          background={{
            movie: `${PUBLIC_MOVIE_PATH}/bg/store.mp4`,
            img: `${PUBLIC_IMAGE_PATH}/bg/store.png`,
          }}
          currentPageIndex={currentPageIndex}
          goNextPageIndex={(index) => {
            setIsLoading(true);
            setTimeout(() => {
              setCurrentPageIndex(index);
            }, 800);
          }}
        />
      )}

      {currentPageIndex === 1 && (
        <LpSection
          stopTime={[900]}
          image={[
            { source: undefined, side: "right" },
            {
              source: `${PUBLIC_IMAGE_PATH}/wallet/AreaSensorPhone.png`,
              side: "right",
            },
          ]}
          background={{
            movie: `${PUBLIC_MOVIE_PATH}/bg/izakaya.mp4`,
            img: `${PUBLIC_IMAGE_PATH}/bg/sp/izakaya.png`,
          }}
          texts={[
            {
              title: "オフラインでも使用可能",
              subtile:
                "電波が悪くてポイントカードがなかなか開けない、そんなことありませんか？デジタルウォレットなら、どんなに電波が悪くてもストレスフリーでカードを提示することができます。",
              side: "leftTop",
            },
          ]}
          // movie={`${PUBLIC_MOVIE_PATH}/izakaya.mp4`}f
          currentPageIndex={currentPageIndex}
          goNextPageIndex={(index) => {
            setIsLoading(true);
            setTimeout(() => {
              setCurrentPageIndex(index);
            }, 800);
          }}
        />
      )}

      {currentPageIndex === 2 && (
        <LpSection
          stopTime={[2400]}
          image={[
            { source: undefined, side: "right" },
            {
              source: `${PUBLIC_IMAGE_PATH}/wallet/AreaSensorPhone.png`,
              side: "right",
            },
          ]}
          texts={[
            {
              title: "インストールが超簡単",
              subtile:
                "QRコードをスキャンするだけで、一瞬でウォレットをインストールすることができます。アプリをいちいちインストールする手間も必要ありません。",
              side: "leftTop",
            },
          ]}
          background={{
            movie: `${PUBLIC_MOVIE_PATH}/bg/install.mp4`,
            img: `${PUBLIC_IMAGE_PATH}/bg/install.png`,
          }}
          // movie={`${PUBLIC_MOVIE_PATH}/install.mp4`}
          currentPageIndex={currentPageIndex}
          goNextPageIndex={(index) => {
            setIsLoading(true);
            setTimeout(() => {
              setCurrentPageIndex(index);
            }, 800);
          }}
        />
      )}

      {currentPageIndex === 3 && (
        <DownloadSection
          sectionTitle={"PLAN"}
          currentPageIndex={currentPageIndex}
          goNextPageIndex={(index) => {
            setIsLoading(true);
            setTimeout(() => {
              setCurrentPageIndex(index);
            }, 800);
          }}
          titles={[
            {
              text: "個人様向け",
              subtext: "¥1,0000",
              source: {
                img: `${PUBLIC_IMAGE_PATH}/bg/sp/ploto.png`,
                movie: `${PUBLIC_MOVIE_PATH}/ploto.mp4`,
              },
              href: "https://design.hamoni.jp/",
            },
            {
              text: "中小企業向け",
              subtext: "2,0000",
              source: {
                img: `${PUBLIC_IMAGE_PATH}/bg/sp/mars.png}`,
                movie: `${PUBLIC_MOVIE_PATH}/mars.mp4`,
              },
              href: "https://app.hamoni.jp/",
            },
            {
              text: "大企業向け",
              subtext: "¥150,000",

              source: {
                img: `${PUBLIC_IMAGE_PATH}/bg/sp/hamoni.png}`,
                movie: `${PUBLIC_MOVIE_PATH}/hamoni.mp4`,
              },
              href: "https://app.hamoni.jp/",
            },
            {
              text: "特殊企業様向け",
              subtext: "¥200,000",

              source: {
                img: `${PUBLIC_IMAGE_PATH}/bg/sp/cancri.png}`,
                movie: `${PUBLIC_MOVIE_PATH}/cancri.mp4`,
              },
              href: "https://app.hamoni.jp/",
            },
          ]}
          downloads={downloads}
        />
      )}

      {isLoading && <Loader />}
    </div>
  );
};

export default Wallet;
