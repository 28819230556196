import { PUBLIC_SVG_PATH } from "../../constants/paths";
import styles from "./Header.module.scss";

type Props = {
  titles: { title: string; onClick: () => void }[];
  downloads: { text: string; href: string; icon: string }[];
};
const Header: React.FC<Props> = ({ titles, downloads }) => {
  return (
    <header className={styles.header}>
      <a href="/" className={styles.header_logo}>
        <img src={`${PUBLIC_SVG_PATH}/logo.svg`} alt="" />
      </a>

      <div className={styles.rights}>
        <div className={styles.headerTitle}>
          {titles.map((item, index) => {
            return (
              <span
                key={index}
                onClick={item.onClick}
                className={`${styles.headerTitle__item} ${styles.headerTitle__item__isActive}`}
              >
                {item.title}
              </span>
            );
          })}
        </div>
        <div className={styles.header_downloadButton}>
          {downloads.map((item, index) => {
            return (
              <a
                key={index}
                className={styles.header_downloadButton_apple}
                href={item.href}
                target="_blank"
                rel="noreferrer"
              >
                <img src={item.icon} alt="" />
                {item.text}
              </a>
            );
          })}
        </div>
      </div>
    </header>
  );
};

export default Header;
