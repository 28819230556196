import { useCallback, useEffect, useRef, useState } from "react";
import styles from "./style.module.scss";
import Particles from "react-particles";
import { loadSlim } from "tsparticles-slim";
import { Engine } from "tsparticles-engine";
import { PUBLIC_IMAGE_PATH, PUBLIC_SVG_PATH } from "../../constants/paths";
import { getBreakpoints } from "../../utils/breakpointsUitls";

type Props = {
  // movie: string;
  background: {
    movie: string;
    img: string;
  };
  image: { side: "left" | "right"; source: string | undefined }[];
  texts: {
    title: string;
    subtile: string;
    side: "leftTop" | "rightTop" | "leftBottom";
  }[];
  goNextPageIndex: (index: number) => void;
  currentPageIndex: number;
  stopTime: number[];
  phoneAppearanceCount?: number;
};

const LpSection: React.FC<Props> = ({
  // movie,
  image,
  texts,
  background,
  // title,
  // subtitle,
  goNextPageIndex,
  currentPageIndex,
  stopTime,
  phoneAppearanceCount,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [lastScrollTop, setLastScrollTop] = useState(0); // 最後のスクロール位置を保持
  const [isFinishAnimate, setIsFinishAnimate] = useState(false);
  const [isStartAnimate, setIsStartAnimate] = useState(!phoneAppearanceCount);
  const [isScrollEnable, setIsScrollEnable] = useState(false);
  const [scrollDownCount, setScrollDownCount] = useState(1);
  const SCREEN_WIDTH = window.innerWidth;

  //初回起動時に開始ポジションまで動画を移動
  useEffect(() => {
    if (getBreakpoints() == "sp") return;
    //スクロール停止
    document.body.style.overflow = "hidden";
    startVideo(stopTime[0]);
  }, []);

  //指定位置まで動画を再生させる関数
  const startVideo = (stopTime: number) => {
    if (getBreakpoints() == "sp") return;
    const video = videoRef.current;
    if (!video) return;

    const handleLoadedMetadata = () => {
      video.play();

      // 指定された時間後にビデオを一時停止
      setTimeout(() => {
        if (!video.paused) {
          video.pause();
          setIsScrollEnable(true);
          document.body.style.overflow = "";
        }
      }, stopTime);

      // イベントリスナーを削除
      video.removeEventListener("loadedmetadata", handleLoadedMetadata);
    };

    if (video.readyState >= 2) {
      // ビデオが既にロードされている場合、直接 handleLoadedMetadata を呼び出す
      handleLoadedMetadata();
    } else {
      // ビデオがまだロードされていない場合、イベントリスナーを追加する
      video.addEventListener("loadedmetadata", handleLoadedMetadata);
    }
  };

  //下にスクロールして、次のページに遷移
  useEffect(() => {
    if (getBreakpoints() == "sp") return;
    const video = videoRef.current;
    if (!video) return;

    // スクロールイベントハンドラ
    const handleScroll = () => {
      const currentScrollTop = window.scrollY;

      //上にスクロール
      const scrollUp = currentScrollTop < lastScrollTop;
      if (scrollUp) {
        goNextPageIndex(currentPageIndex - 1);
        setIsFinishAnimate(false);
      }

      //下にスクロール
      const scrollDown = currentScrollTop > lastScrollTop;
      if (scrollDown && isScrollEnable) {
        // 下にスクロールされたら動画を再生
        console.log("下にスクロールを検知");

        //stopTimeとscrollDownCountが一致したら次のページに遷移
        if (scrollDownCount === stopTime.length) {
          console.log("次のページに遷移");
          const video = videoRef.current;
          video?.play();
          setIsFinishAnimate(true);
          goNextPageIndex(currentPageIndex + 1);
          document.body.style.overflow = "hidden";
        } else {
          console.log("とどまる");
          startVideo(stopTime[scrollDownCount]);
          document.body.style.overflow = "hidden";
          setScrollDownCount((count) => count + 1);
        }

        if (phoneAppearanceCount === scrollDownCount) {
          console.log("setIsStartAnimate");
          setIsStartAnimate(true);
        }
      }

      setLastScrollTop(currentScrollTop);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollTop]);

  const particlesInit = useCallback(async (engine: Engine) => {
    await loadSlim(engine);
  }, []);

  const particlesLoaded = useCallback(async (container: any) => {
    await console.log(container);
  }, []);

  console.log("scrollDownCount", scrollDownCount);

  return (
    <div className={styles.container}>
      <video
        ref={videoRef}
        className={styles.firstVideo}
        src={background.movie}
        muted
      ></video>

      <img className={styles.firstImg} src={background.img} alt="" />

      {texts.map((item, index) => {
        if (scrollDownCount === index + 1) {
          return (
            <div
              key={index}
              className={`${styles.titles} ${item.side === "leftBottom" && styles.titlesBottom
                } ${item.side === "rightTop" && styles.titlesSecond} ${styles.bounceInRight
                } ${isFinishAnimate ? styles.slideLeftFadeOut : ""}`}
            >
              <h2>{item.title}</h2>

              <p className={`${styles.subtitle} ${styles.slideInFromRight}`}>
                {item.subtile}
              </p>

              <div
                className={styles.nextPageButton}
                onClick={() => {
                  if (getBreakpoints() == "sp") {
                    window.location.href = "https://app.hamoni.jp/";
                  } else {
                    goNextPageIndex(currentPageIndex + 1)
                  }
                }}
              >
                <img src={`${PUBLIC_SVG_PATH}/downArrow.svg`} alt="" />
                <p className={styles.linearWipe}>{getBreakpoints() == "sp" ? "go web page" : "go next page"}</p>
              </div>
            </div>
          );
        }
      })}

      {image.map((item, index) => {
        if (scrollDownCount === index + 1 && item.source) {
          return (
            <img
              key={index}
              className={`${styles.firstPhone} ${item.side === "left" && styles.secondPhone
                } ${isStartAnimate ? styles.rotateAndBounceIn : ""} ${isFinishAnimate ? styles.rotateAndBounceOut : ""
                }`}
              src={item.source}
              alt=""
            />
          );
        }
      })}

      <div className={styles.header__bg}>
        <Particles
          id="tsparticles"
          init={particlesInit}
          loaded={particlesLoaded}
          options={{
            fpsLimit: 120,
            interactivity: {
              events: {
                onClick: {
                  enable: true,
                  mode: "push",
                },
                onHover: {
                  enable: true,
                  mode: "repulse",
                },
                resize: true,
              },
              modes: {
                push: {
                  quantity: 4,
                },
                repulse: {
                  distance: 200,
                  duration: 100,
                },
              },
            },
            particles: {
              color: {
                value: ["#ffffff", "#00B0B2", "#F8D700", "#F08300", "#EA544F"],
              },
              links: {
                color: "#ffffff",
                distance: 0,
                enable: true,
                opacity: 0.5,
                width: 1,
              },
              move: {
                direction: "none",
                enable: true,
                outModes: {
                  default: "bounce",
                },
                random: false,
                speed: 0.3,
                straight: false,
              },
              number: {
                density: {
                  enable: true,
                  area: 800,
                },
                value: 20,
              },
              opacity: {
                value: 0.5,
              },
              shape: {
                type: "circle",
              },
              size: {
                value: { min: 1, max: 3 },
              },
            },
            detectRetina: true,
          }}
        />
      </div>
    </div>
  );
};

export default LpSection;
