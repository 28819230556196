import React from "react";
import { Helmet } from "react-helmet";

type Props = {
  title: string;
  description?: string;
};

const MetaDescription: React.FC<Props> = ({ title, description }) => {
  return (
    <Helmet>
      <title>{title}</title>
      {description ?? <meta name="description" content={`${description}`} />}
    </Helmet>
  );
};

export default MetaDescription;
